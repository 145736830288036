import ApexCharts from 'apexcharts';
// $(document).on('turbolinks:load', function () {
//         var options = {
//       chart: {
//         height: 320,
//         type: 'bar',
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//           columnWidth: '35%',
//           endingShape: 'rounded'
//         },
//       },
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         show: true,
//         width: 1,
//         colors: ['transparent']
//       },
//       colors: ['#6a4ffc', '#2962ff', '#a64edd', '#29a74c', '#ff4560'],
//       series: [{
//         name: 'Egress General',
//         data: <%= raw @chart_data[:egress_general].to_json %>
//       }, {
//         name: 'Egress Operational',
//         data: <%= raw @chart_data[:egress_operational].to_json %>
//       }, {
//         name: 'Egress Tax',
//         data: <%= raw @chart_data[:egress_tax].to_json %>
//       }, {
//         name: 'Ingress General',
//         data: <%= raw @chart_data[:ingress_general].to_json %>
//       }, {
//         name: 'Ingress Tax',
//         data: <%= raw @chart_data[:ingress_tax].to_json %>
//       }],
//       xaxis: {
//         categories: <%= raw @months.to_json %>,
//         labels: {
//           style: {
//             colors: '#686c71',
//             fontSize: '12px',
//           },
//         },
//       },
//       fill: {
//         opacity: 1
//       },
//       tooltip: {
//         y: {
//           formatter: function (val) {
//             return "$ " + val.toFixed(2)
//           }
//         }
//       },
//       legend: {
//         offsetY: -10,
//       },
//       grid: {
//         show: true,
//         borderColor: '#f6f6f7',
//       },
//       yaxis: {
//         labels: {
//           style: {
//             color: '#686c71',
//             fontSize: '12px',
//           },
//         },
//         axisBorder: {
//           show: false,
//           color: '#f6f6f7',
//         },
//       }
//     }

//     var chart = new ApexCharts(
//       document.querySelector("#month-transactions-statistics"),
//       options
//     );
//     chart.render();
// });